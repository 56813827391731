/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:155ea4e3-9789-4d4c-843f-d7c9789c40d6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ETiERJauS",
    "aws_user_pools_web_client_id": "7m38198up1jitpej7111m7adto",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "awsmobilePreProd": {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:155ea4e3-9789-4d4c-843f-d7c9789c40d6",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_ETiERJauS",
        "aws_user_pools_web_client_id": "7m38198up1jitpej7111m7adto",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OPTIONAL",
        "aws_cognito_mfa_types": [
            "TOTP"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": "8",
            "passwordPolicyCharacters": [
                "REQUIRES_LOWERCASE",
                "REQUIRES_UPPERCASE",
                "REQUIRES_NUMBERS",
                "REQUIRES_SYMBOLS"
            ]
        },
        "aws_cognito_verification_mechanisms": [
            "PHONE_NUMBER"
        ]
    },
    "awsmobileProd": {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:27db4dde-5e3b-4efa-b8b5-ce900df16a01",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_2tHpfQ5qA",
        "aws_user_pools_web_client_id": "q0a2ud9bccieam9gk7jo4elb4",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "ON",
        "aws_cognito_mfa_types": [
            "SMS",
            "TOTP"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": [
                "REQUIRES_LOWERCASE",
                "REQUIRES_UPPERCASE",
                "REQUIRES_NUMBERS",
                "REQUIRES_SYMBOLS"
            ]
        },
        "aws_cognito_verification_mechanisms": [
            "PHONE_NUMBER"
        ]
    },
    "awsmobileLocal": {
        "aws_project_region": "us-east-1",
        "oauth": {},
        "apiEndpoint": "http://localhost:3000"
    }
};


export default awsmobile;
